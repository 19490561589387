@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@layer base {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 0;
    outline: 0;
    text-decoration: none;
    list-style-type: none;
  }

  :root {
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(72, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);
    --transition: all 400ms ease;
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
  }

  body {
    font-family: "Poppins", sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    background-image: url("../src/assets/bg-texture.png");
  }
  html {
    scroll-behavior: smooth;
  }

  ::-webkit-scrollbar {
    display: none;
    /* this removes the scroll bar on the body of the browser */
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 500;
  }

  h1 {
    font-size: 2.5rem;
  }

  section > h2,
  section > h5 {
    text-align: center;
    color: var(--color-light);
  }

  section > h2 {
    color: var(--color-primary);
  }
  a {
    color: var(--color-primary);
    transition: var(--transition);
  }

  a:hover {
    color: var(--color-white);
  }
  img {
    display: block;
    width: 100%;
    object-fit: cover;
  }
  nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
    transition: "var(--transition)";
  }
  nav a:hover {
    background: rgba(0, 0, 0, 0.3);
  }
  nav a.active {
    background: var(--color-primary);
    color: var(--color-bg);
  }

  #about .active article {
    background: var(--color-bg-variant);
  }
  ul.aboutList {
    position: relative;
  }

  ul.aboutList li {
    position: relative; /* so that pseudoelements are positioned relatively to their "li"s*/
    /* use padding-bottom instead of margin-bottom.*/
    margin-bottom: 0; /* This overrides previously specified margin-bottom */
    padding-bottom: 1.2rem;
    padding-left: 1rem;
  }

  ul.aboutList li:after {
    /* bullets */
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
    position: absolute;
    left: -19.5px; /*adjust manually*/
    top: 5px;
  }

  ul.aboutList li:before {
    /* lines */
    content: "";
    position: absolute;
    left: -16px; /* adjust manually */
    border-left: 1px solid #ffffff;
    height: 100%;
    width: 1px;
  }

  ul.aboutList li:first-child:before {
    /* first li's line */
    top: 6px; /* moves the line down so that it disappears under the bullet. Adjust manually */
  }

  ul.aboutList li:last-child:before {
    /* last li's line */
    height: 6px; /* shorten the line so it goes only up to the bullet. Is equal to first-child:before's top */
  }

  .swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
  }

  input,
  textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
  }
  footer a {
    color: var(--color-bg);
  }
}
